//
// gallery.scss
//

.portfolioFilter {
    a {
        transition: all 0.3s ease-out;
        color: #{map-get($grays, "900")};
        border-radius: 3px;
        padding: 5px 10px;
        display: inline-block;
        font-weight: $font-weight-medium;
        &:hover {
            color: $primary !important;
        }
    }
    a.current {
        color: $primary !important;
    }
}

.thumb {
    background-color: $card-bg;
    border-radius: 3px;
    box-shadow: $box-shadow-sm;
    margin-top: 24px;
    padding: 10px;
}

.thumb-img {
    border-radius: 2px;
    overflow: hidden;
}

.gal-detail {
    h4 {
        margin-top: 16px;
        font-size: 16px;
    }
}

// light box
.ReactModal__Body--open {
    .ReactModalPortal {
        > div {
            z-index: 1031 !important;
        }
    }
    .ril-toolbar {
        align-items: center;
        .ril-toolbar__item__child {
            p {
                margin-bottom: 0;
                line-height: 2.6;
            }
        }
    }
}

// dark mode
body[data-layout-color="dark"] {
    .portfolioFilter {
        a {
            color: #{map-get($dark-grays, "800")};
        }
    }
}
