//
// Datepicker
//

$datepicker__background-color: $dropdown-bg;
$datepicker__border-color: $dropdown-border-color;
$datepicker__highlighted-color: $primary;
$datepicker__muted-color: #{map-get($grays, "600")};
$datepicker__selected-color: $primary;
$datepicker__text-color: #{map-get($grays, "600")};
$datepicker__header-color: #{map-get($grays, "600")};
$datepicker__navigation-disabled-color: #{lighten(map-get($grays, "600"), 10%)};

$datepicker__border-radius: 0.3rem;
$datepicker__day-margin: 0.166rem;
$datepicker__font-size: $font-size-base;
$datepicker__font-family: $font-family-base;

$datepicker__item-size: 2rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 8px;

%triangle-arrow {
    margin-left: -$datepicker__triangle-size;
    position: absolute;

    &,
    &::before {
        box-sizing: content-box;
        position: absolute;
        border: $datepicker__triangle-size solid transparent;

        height: 0;
        width: 1px;
    }

    &::before {
        content: "";
        z-index: -1;
        border-width: $datepicker__triangle-size;

        left: -$datepicker__triangle-size;
        border-bottom-color: $datepicker__border-color;
    }
}

%triangle-arrow-up {
    @extend %triangle-arrow;

    top: 0;
    margin-top: -$datepicker__triangle-size;

    &,
    &::before {
        border-top: none;
        border-bottom-color: $datepicker__muted-color;
    }

    &::before {
        top: 0px;
        border-bottom-color: $input-bg;
    }
}

%triangle-arrow-down {
    @extend %triangle-arrow;

    bottom: 0;
    margin-bottom: -$datepicker__triangle-size;

    &,
    &::before {
        border-bottom: none;
        border-top-color: $datepicker__muted-color;
    }

    &::before {
        bottom: 0px;
        border-top-color: $input-bg;
    }
}

.react-datepicker-wrapper {
    display: block;
}

.react-datepicker {
    font-family: $datepicker__font-family;
    font-size: $datepicker__font-size;
    background-color: $dropdown-bg;
    color: $datepicker__text-color;
    border: $border-width solid $datepicker__border-color;
    border-radius: $datepicker__border-radius;
    display: inline-block;
    position: relative;
    .react-datepicker__day--outside-month {
        color: #{lighten(map-get($grays, "600"), 15%)};
    }
}

.react-datepicker--time-only {
    .react-datepicker__triangle {
        left: 35px;
    }

    .react-datepicker__time-container {
        border-left: 0;
    }

    .react-datepicker__time {
        border-radius: 0.3rem;
    }

    .react-datepicker__time-box {
        border-radius: 0.3rem;
    }
}

.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}

.react-datepicker-popper {
    z-index: 1;

    &[data-placement^="bottom"] {
        padding-top: $datepicker__triangle-size + 2px;

        .react-datepicker__triangle {
            @extend %triangle-arrow-up;
        }
    }

    &[data-placement^="top"] {
        padding-bottom: $datepicker__triangle-size + 2px;

        .react-datepicker__triangle {
            @extend %triangle-arrow-down;
        }
    }

    &[data-placement^="right"] {
        padding-left: $datepicker__triangle-size;

        .react-datepicker__triangle {
            left: auto;
            right: 42px;
        }
    }

    &[data-placement^="left"] {
        padding-right: $datepicker__triangle-size;

        .react-datepicker__triangle {
            left: 42px;
            right: auto;
        }
    }
}

.react-datepicker__header {
    text-align: center;
    background-color: $datepicker__background-color;
    border-bottom: 1px solid $datepicker__border-color;
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
    padding-top: 8px;
    position: relative;

    &--time {
        padding-bottom: 8px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: $datepicker__header-color;
    font-weight: bold;
    font-size: $datepicker__font-size;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    line-height: $datepicker__item-size;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: $datepicker__navigation-size solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;

    &--previous {
        left: 10px;
        border-right-color: $datepicker__muted-color;

        &:hover {
            border-right-color: #{darken(map-get($grays, "600"), 10%)};
        }

        &--disabled,
        &--disabled:hover {
            border-right-color: $datepicker__navigation-disabled-color;
            cursor: default;
        }
    }

    &--next {
        right: 10px;
        border-left-color: $datepicker__muted-color;

        &--with-time:not(&--with-today-button) {
            right: 80px;
        }

        &:hover {
            border-left-color: #{darken(map-get($grays, "600"), 10%)};
        }

        &--disabled,
        &--disabled:hover {
            border-left-color: $datepicker__navigation-disabled-color;
            cursor: default;
        }
    }

    &--years {
        position: relative;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &-previous {
            top: 4px;
            border-top-color: $datepicker__muted-color;

            &:hover {
                border-top-color: #{darken(map-get($grays, "600"), 10%)};
            }
        }

        &-upcoming {
            top: -4px;
            border-bottom-color: $datepicker__muted-color;

            &:hover {
                border-bottom-color: #{darken(map-get($grays, "600"), 10%)};
            }
        }
    }
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__month {
    margin: $datepicker__margin;
    text-align: center;

    .react-datepicker__month-text {
        display: inline-block;
        width: 4rem;
        margin: 2px;
    }
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;

    .react-datepicker-time__caption {
        display: inline-block;
    }

    .react-datepicker-time__input-container {
        display: inline-block;

        .react-datepicker-time__input {
            display: inline-block;
            margin-left: 10px;

            input {
                width: 85px;
            }

            input[type="time"]::-webkit-inner-spin-button,
            input[type="time"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type="time"] {
                -moz-appearance: textfield;
            }
        }

        .react-datepicker-time__delimiter {
            margin-left: 5px;
            display: inline-block;
        }
    }
}

.react-datepicker__time-container {
    float: right;
    border-left: 1px solid $datepicker__border-color;
    width: 100px;

    &--with-today-button {
        display: inline;
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        position: absolute;
        right: -72px;
        top: 0;
    }

    .react-datepicker__time {
        position: relative;
        background: $input-bg;

        .react-datepicker__time-box {
            width: 100px;
            overflow-x: hidden;
            margin: 0 auto;
            text-align: center;

            ul.react-datepicker__time-list {
                list-style: none;
                margin: 0;
                height: calc(195px + (#{$datepicker__item-size} * 0.5));
                overflow-y: scroll;
                padding-right: 0px;
                padding-left: 0px;
                width: 100%;
                box-sizing: content-box;

                li.react-datepicker__time-list-item {
                    height: 30px;
                    padding: 5px 10px;
                    outline: none !important;

                    &:hover {
                        cursor: pointer;
                        background-color: $datepicker__background-color;
                    }

                    &--selected {
                        background-color: $datepicker__selected-color;
                        color: white;
                        font-weight: bold;

                        &:hover {
                            background-color: $datepicker__selected-color;
                        }
                    }

                    &--disabled {
                        color: $datepicker__muted-color;

                        &:hover {
                            cursor: default;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}

.react-datepicker__week-number {
    color: $datepicker__muted-color;
    display: inline-block;
    width: $datepicker__item-size;
    line-height: $datepicker__item-size;
    text-align: center;
    margin: $datepicker__day-margin;

    &.react-datepicker__week-number--clickable {
        cursor: pointer;

        &:hover {
            border-radius: $datepicker__border-radius;
            background-color: $datepicker__background-color;
        }
    }
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: $datepicker__text-color;
    display: inline-block;
    width: $datepicker__item-size;
    // height: $datepicker__item-size;
    line-height: $datepicker__item-size;
    text-align: center;
    margin: $datepicker__day-margin;
}

.react-datepicker__month {
    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: $datepicker__border-radius;
        background-color: $datepicker__selected-color;
        color: #fff;

        &:hover {
            background-color: darken($datepicker__selected-color, 5%);
        }
    }

    &--disabled {
        color: $datepicker__muted-color;
        pointer-events: none;

        &:hover {
            cursor: default;
            background-color: transparent;
        }
    }
}

.react-datepicker__day,
.react-datepicker__month-text {
    cursor: pointer;

    &:hover {
        border-radius: $datepicker__border-radius;
        background-color: $datepicker__highlighted-color;
        color: #fff !important;
    }

    &--today {
        font-weight: bold;
    }

    &--highlighted {
        border-radius: $datepicker__border-radius;
        background-color: $datepicker__highlighted-color;
        color: #fff;

        &:hover {
            background-color: darken($datepicker__highlighted-color, 5%);
        }

        &-custom-1 {
            color: magenta;
        }

        &-custom-2 {
            color: green;
        }
    }

    &--selected,
    &--in-selecting-range,
    &--in-range {
        border-radius: $datepicker__border-radius;
        background-color: $datepicker__selected-color;
        color: #fff;

        &:hover {
            background-color: darken($datepicker__selected-color, 5%);
        }
    }

    &--keyboard-selected {
        border-radius: $datepicker__border-radius;
        background-color: lighten($datepicker__selected-color, 10%);
        color: #fff;

        &:hover {
            background-color: darken($datepicker__selected-color, 5%);
        }
    }

    &--in-selecting-range:not(&--in-range) {
        background-color: rgba($datepicker__selected-color, 0.5);
    }

    &--in-range:not(&--in-selecting-range) {
        .react-datepicker__month--selecting-range & {
            background-color: $datepicker__background-color;
            color: $datepicker__text-color;
        }
    }

    &--disabled {
        cursor: default;
        color: $datepicker__muted-color;

        &:hover {
            background-color: transparent;
        }
    }
}

.react-datepicker__month-text {
    &.react-datepicker__month--selected,
    &.react-datepicker__month--in-range {
        &:hover {
            background-color: $datepicker__selected-color;
        }
    }

    &:hover {
        background-color: $datepicker__background-color;
    }
}

.react-datepicker__input-container {
    position: relative;
    display: block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: $datepicker__border-radius;

    &:hover {
        cursor: pointer;

        .react-datepicker__year-read-view--down-arrow,
        .react-datepicker__month-read-view--down-arrow {
            border-top-color: #{darken(map-get($grays, "600"), 10%)};
        }
    }

    &--down-arrow {
        @extend %triangle-arrow-down;
        border-top-color: $datepicker__muted-color;
        float: right;
        margin-left: 20px;
        top: 8px;
        position: relative;
        border-width: $datepicker__navigation-size;
    }
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: $datepicker__background-color;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: $datepicker__border-radius;
    border: 1px solid $datepicker__border-color;

    &:hover {
        cursor: pointer;
    }

    &--scrollable {
        height: 150px;
        overflow-y: scroll;
    }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
        border-top-left-radius: $datepicker__border-radius;
        border-top-right-radius: $datepicker__border-radius;
    }

    &:last-of-type {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom-left-radius: $datepicker__border-radius;
        border-bottom-right-radius: $datepicker__border-radius;
    }

    &:hover {
        background-color: $datepicker__muted-color;

        .react-datepicker__navigation--years-upcoming {
            border-bottom-color: #{darken(map-get($grays, "600"), 10%)};
        }

        .react-datepicker__navigation--years-previous {
            border-top-color: #{darken(map-get($grays, "600"), 10%)};
        }
    }

    &--selected {
        position: absolute;
        left: 15px;
    }
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;

    &::after {
        background-color: $datepicker__selected-color;
        border-radius: 50%;
        bottom: 0;
        box-sizing: border-box;
        color: #fff;
        content: "\00d7";
        cursor: pointer;
        font-size: 12px;
        height: 16px;
        width: 16px;
        line-height: 1;
        margin: -8px auto 0;
        padding: 2px;
        position: absolute;
        right: 0px;
        text-align: center;
    }
}

.react-datepicker__today-button {
    background: $datepicker__background-color;
    border-top: 1px solid $datepicker__border-color;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 3rem;
        line-height: 3rem;
    }

    // Resize for small screens
    @media (max-width: 400px), (max-height: 550px) {
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            width: 2rem;
            line-height: 2rem;
        }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        font-size: $datepicker__font-size * 1.8;
    }

    .react-datepicker__navigation {
        border: 1.8 * $datepicker__navigation-size solid transparent;
    }

    .react-datepicker__navigation--previous {
        border-right-color: $datepicker__muted-color;

        &:hover {
            border-right-color: #{darken(map-get($grays, "600"), 10%)};
        }

        &--disabled,
        &--disabled:hover {
            border-right-color: $datepicker__navigation-disabled-color;
            cursor: default;
        }
    }

    .react-datepicker__navigation--next {
        border-left-color: $datepicker__muted-color;

        &:hover {
            border-left-color: #{darken(map-get($grays, "600"), 10%)};
        }

        &--disabled,
        &--disabled:hover {
            border-left-color: $datepicker__navigation-disabled-color;
            cursor: default;
        }
    }
}

// remove outline on focus
.react-datepicker__day:focus {
    outline: none !important;
}

// calendar widget
.calendar-widget {
    > div {
        width: 100%;
    }
    .react-datepicker {
        width: 100%;

        .react-datepicker__month-container {
            width: 100%;

            .react-datepicker__current-month {
                font-size: 1.1rem;
            }

            .react-datepicker__day-names,
            .react-datepicker__week {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                height: 52px;
            }
        }
    }
}

// dark mode
body[data-layout-color="dark"] {
    $datepicker__muted-color: #{map-get($dark-grays, "600")};
    $datepicker__text-color: #{map-get($dark-grays, "600")};
    $datepicker__header-color: #{map-get($dark-grays, "600")};
    $datepicker__navigation-disabled-color: #{lighten(map-get($dark-grays, "600"), 10%)};
    .react-datepicker {
        .react-datepicker__day--outside-month {
            color: #{lighten(map-get($dark-grays, "600"), 15%)};
        }
    }
    .react-datepicker__navigation {
        &--previous {
            &:hover {
                border-right-color: #{darken(map-get($dark-grays, "600"), 10%)};
            }
        }
        &--next {
            &:hover {
                border-left-color: #{darken(map-get($dark-grays, "600"), 10%)};
            }
        }
        &--years {
            &-previous {
                &:hover {
                    border-top-color: #{darken(map-get($dark-grays, "600"), 10%)};
                }
            }
            &-upcoming {
                &:hover {
                    border-bottom-color: #{darken(map-get($dark-grays, "600"), 10%)};
                }
            }
        }
    }
    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
        &:hover {
            .react-datepicker__year-read-view--down-arrow,
            .react-datepicker__month-read-view--down-arrow {
                border-top-color: #{darken(map-get($dark-grays, "600"), 10%)};
            }
        }
    }
    .react-datepicker__year-option,
    .react-datepicker__month-option,
    .react-datepicker__month-year-option {
        &:hover {
            .react-datepicker__navigation--years-upcoming {
                border-bottom-color: #{darken(map-get($dark-grays, "600"), 10%)};
            }

            .react-datepicker__navigation--years-previous {
                border-top-color: #{darken(map-get($dark-grays, "600"), 10%)};
            }
        }
    }
    .react-datepicker__portal {
        .react-datepicker__navigation--previous {
            &:hover {
                border-right-color: #{darken(map-get($dark-grays, "600"), 10%)};
            }
        }
        .react-datepicker__navigation--next {
            &:hover {
                border-left-color: #{darken(map-get($dark-grays, "600"), 10%)};
            }
        }
    }
}
