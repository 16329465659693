//
// card.scss
//

.card {
    // box-shadow: $box-shadow-sm;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; --by Bindesh & Dhruv
    margin-bottom: $grid-gutter-width;
    // border-radius: 20px;
    //border-radius: 0.1rem;
    //border: 0.1px solid darkgrey;
    border-radius: 8px;
    //box-shadow: 0px 5px 10px rgba(169, 169, 169, 0.3);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    .card-drop {
        color: #{map-get($grays, '900')};
        font-size: 20px;
        display: inline-block;
        line-height: 1px;
    }
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: '\F0415';
                }
            }
        }
    }
}

// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}

//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: $card-overlay-bg;
    cursor: progress;
}

.header-title {
    font-size: 1rem;
    margin: 0 0 7px 0;
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}

// dark mode
body[data-layout-color='dark'] {
    .card {
        .card-drop {
            color: #{map-get($dark-grays, '600')};
        }
    }
}
